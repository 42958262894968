export const NETWORKS = {
    1: {
        rpc: ['https://mainnet.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'etherscan.com',
        network_name: 'Ethereum Mainnet',
    },
    3: {
        rpc: ['https://ropsten.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'ropsten.etherscan.com',
        network_name: 'Ropsten',
    },
    4: {
        rpc: ['https://rinkeby.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'rinkeby.etherscan.com',
        network_name: 'Rinkeby',
    },
    5: {
        rpc: ['https://goerli.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'georli.etherscan.com',
        network_name: 'Georli',
    },
    56: {
        rpc: ['https://bsc-dataseed.binance.org/'],
        symbol: 'BNB',
        explorer_url: 'bscscan.com',
        network_name: 'Binance Smart Chain Mainnet',
    },
    97: {
        rpc: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        symbol: 'BNB',
        explorer_url: 'testnet.bscscan.com',
        network_name: 'Binance Smart Chain Testnet',
    },
    137: {
        rpc: ['https://rpc-mumbai.matic.today'],
        symbol: 'MATIC',
        explorer_url: 'polygonscan.com',
        network_name: 'Polygon Mainnet',
    },
    80001: {
        rpc: ['https://rpc-mumbai.matic.today'],
        symbol: 'MATIC',
        explorer_url: 'mumbai.polygonscan.com',
        network_name: 'Polygon Mumbai Testnet',
    },
    43114: {
        rpc: ['https://api.avax.network/ext/bc/C/rpc'],
        symbol: 'AVAX',
        explorer_url: 'snowtrace.io',
        network_name: 'Avalanche C-Chain',
    },
    43113: {
        rpc: ['https://api.avax-test.network/ext/bc/C/rpc'],
        symbol: 'AVAX',
        explorer_url: 'testnet.snowtrace.io',
        network_name: 'Avalanche Fuji Testnet',
    },
    1337: {
        rpc: ['http://localhost:8545/'],
        symbol: 'ETH',
        explorer_url: 'testnet.bscscan.com',
        network_name: 'Localhost',
    },
};
