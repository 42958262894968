import { isAddress } from '@ethersproject/address';
import { Contract, ContractInterface } from '@ethersproject/contracts';

import { provider } from '@src/bootstrap/web3';
import {
    COMPOUNDER,
    COMPOUND_STAKING,
    LEGACY_COMPOUND,
    LOGO_STORE,
    MULTI_ASSET_STAKING,
} from '@src/config';
import { ContractType } from '@src/ts/constants';

import CompoundStaking from './abi/CompoundStaking.json';
import LegacyCompound from './abi/LegacyCompound.json';
import ERC20 from './abi/ERC20.json';
import ERC721 from './abi/ERC721.json';
import LogoStore from './abi/LogoStore.json';
import MultiAssetStaking from './abi/MultiAssetStaking.json';
import Vault from './abi/Vault.json';

export const contract = {
    [ContractType.ERC20]: {
        abi: ERC20,
        address: '',
    },
    [ContractType.CompoundStaking]: {
        abi: LEGACY_COMPOUND ? LegacyCompound : CompoundStaking,
        address: COMPOUND_STAKING,
    },
    [ContractType.LogoStore]: {
        abi: LogoStore,
        address: LOGO_STORE,
    },
    [ContractType.MultiAssetStaking]: {
        abi: MultiAssetStaking,
        address: MULTI_ASSET_STAKING,
    },
    [ContractType.Vault]: {
        abi: Vault,
        address: COMPOUNDER,
    },
};

export const getContract = (type: ContractType): Contract => {
    const { address, abi } = contract[type];
    return new Contract(address, abi, provider);
};

export const newContract = (
    address: string,
    abi: ContractInterface,
): Contract => new Contract(address, abi, provider);

export const getERC20Contract = (address: string): Contract => {
    if (!isAddress(address)) return null;
    return new Contract(address, ERC20, provider);
};

export const getERC721Contract = (address: string): Contract => {
    if (!isAddress(address)) return null;
    return new Contract(address, ERC721, provider);
};

export const getABI = (type: ContractType) => contract[type].abi;
