import {
    Action,
    PayloadAction,
    ThunkDispatch,
    createSlice,
} from '@reduxjs/toolkit';

import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { ContractType } from '@src/ts/constants';
import { RootState } from '@src/bootstrap/store';
import { StakingPool } from '@src/ts/interfaces';
import { StakingVersion } from '@src/ts/types';
import { getContract } from '@src/contracts';
import { staking_contract_versions } from '@src/constants';

const opts = ['Deposit', 'Withdraw'];

interface StakingState {
    loading: boolean;
    current_pool: string;
    pools: StakingPool[];
    modal_state: string;
    deposit_amount: string;
}

const initialState = {
    loading: false,
    current_pool: '',
    pools: [],
    modal_state: opts[0],
    deposit_amount: '0',
} as StakingState;

const stakingSlice = createSlice({
    name: 'staking',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setCurrentPool(state, action: PayloadAction<string>) {
            state.current_pool = action.payload;
        },
        setPool(state, action: PayloadAction<StakingPool>) {
            const idx = state.pools.findIndex(
                ({ pool_id }) => pool_id === action.payload.pool_id,
            );
            state.pools[idx] = action.payload;
        },
        setPools(state, action: PayloadAction<StakingPool[]>) {
            state.pools = action.payload;
        },
        setModalState(state, action: PayloadAction<string>) {
            state.modal_state = action.payload;
        },
        setDepositAmount(state, action: PayloadAction<string>) {
            state.deposit_amount = action.payload;
        },
    },
});

const getPool = async (
    idx: number,
    account: string,
    version: StakingVersion,
    staking: Contract,
) => {
    const pool = await staking.poolInfo(idx);

    const pool_info: Record<string, unknown> = {
        total_staked: pool.totalDeposit.toString(),
    };

    if (version === 'compound') {
        const compounder = getContract(ContractType.Vault);

        const user = await compounder.users(idx, account);
        const ts = user.lastDepositedTime.mul(1000).toNumber();

        pool_info['earned_reward'] = (
            await compounder.getRewardOfUser(account, idx)
        ).toString();

        pool_info['can_claim'] = await compounder.canUnstake(account, idx);

        pool_info['user_stake'] = user.totalInvested.toString();
        pool_info['user_shares'] = user.shares.toString();
        pool_info['claimed_reward'] = user.totalClaimed.toString();

        pool_info['has_stake'] = BigNumber.from(pool_info.user_stake).gt(0);
        pool_info['last_user_deposit'] =
            ts !== 0 ? new Date(ts).toISOString() : null;
    } else {
        const user = await staking.users(idx, account);
        const ts = user.depositTime.mul(1000).toNumber();

        pool_info['earned_reward'] = (
            await staking.payout(idx, account)
        ).toString();

        pool_info['can_claim'] = await staking.canUnstake(idx, account);

        pool_info['user_stake'] = user[0].toString();
        pool_info['claimed_reward'] = user.totalClaimed.toString();

        pool_info['last_user_deposit'] =
            ts !== 0 ? new Date(ts).toISOString() : null;
        pool_info['has_stake'] = BigNumber.from(pool_info.user_stake).gt(0);
        pool_info['is_reward_above_input'] = pool?.isRewardAboveInput;
    }

    pool_info['user_multiplier'] = (
        await staking.calcMultiplier(idx, account)
    ).toString();

    return pool_info;
};

export const updatePool = (
    pool_id: string,
    account: string,
    initial_pool?: StakingPool,
): ((
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState,
) => void) => {
    return (
        dispatch: ThunkDispatch<RootState, void, Action>,
        getState: () => RootState,
    ): void => {
        dispatch(setLoading(true));

        const pool =
            initial_pool ||
            getState().staking.pools.find((p) => p.pool_id === pool_id);

        const [version, idx] = pool_id.split(':');
        const staking = getContract(staking_contract_versions[version]);

        getPool(Number(idx), account, version as StakingVersion, staking).then(
            (user_data) => {
                dispatch(setPool({ ...pool, ...user_data }));
                dispatch(setLoading(false));
            },
        );
    };
};

export const updatePools = (
    account?: string,
    load = true,
    initial_pools?: StakingPool[],
): ((
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState,
) => void) => {
    return (
        dispatch: ThunkDispatch<RootState, void, Action>,
        getState: () => RootState,
    ): void => {
        if (load) {
            dispatch(setLoading(true));
        }
        const pools = initial_pools || getState().staking.pools;

        Promise.all(
            pools.map(async (p) => {
                if (account) {
                    const [version, pool_id] = p.pool_id.split(':');
                    const staking = getContract(
                        staking_contract_versions[version],
                    );

                    const user_data = await getPool(
                        Number(pool_id),
                        account,
                        version as StakingVersion,
                        staking,
                    );
                    return user_data;
                }

                return {};
            }),
        )
            .then((all_user_data) => {
                const updated_pools = pools.map((p, idx) => ({
                    ...p,
                    ...all_user_data[idx],
                }));
                dispatch(setPools(updated_pools));
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                console.log(err);
            });
    };
};

export const {
    setCurrentPool,
    setLoading,
    setPool,
    setPools,
    setModalState,
    setDepositAmount,
} = stakingSlice.actions;
export default stakingSlice.reducer;
