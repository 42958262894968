import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { AppProps } from 'next/app';

import 'tailwindcss/tailwind.css';
import '@src/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import { store } from '@src/bootstrap';
import { Layout } from '@src/components/Layout';
import { Web3onboardProvider } from '@src/hooks/useWeb3Onboard';

import web3Onboard from '@src/web3onboard';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    useEffect(() => {
        const walletsSub = web3Onboard.state.select('wallets');
        const { unsubscribe } = walletsSub.subscribe((wallets) => {
            const connectedWallets = wallets.map(({ label }) => label);
            window.localStorage.setItem(
                'connectedWallets',
                JSON.stringify(connectedWallets),
            );
        });
        return unsubscribe;
    }, []);

    return (
        <Web3onboardProvider>
            <Provider store={store}>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </Provider>
        </Web3onboardProvider>
    );
}

export default MyApp;
