import { ContractType } from '@src/ts/constants';

/* eslint-disable-next-line */
declare let window: any;

export const nums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];

export const BASE_IMAGE_URL =
    'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com';

export const is_browser = typeof window !== 'undefined';

export const primary_button_classes =
    'py-2 px-6 rounded-3xl focus:outline-none ring-opacity-75 ring-pink-500 text-white bg-primary hover:bg-primary-hover text-center';

export const TOKEN_DECIMALS = {
    HFT: 9,
};

export const ETHER_UNITS = {
    9: 'gwei',
    18: 'ether',
};

export const MAX_INT =
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const provider_is_meta_mask =
    is_browser &&
    typeof window.web3 !== 'undefined' &&
    window.web3.currentProvider.isMetaMask === true;

export const staking_contract_versions = {
    compound: ContractType.CompoundStaking,
    // 'multi-asset': ContractType.MultiAssetStaking,
};
