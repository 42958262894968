import Head from 'next/head';

import { MetaProps } from '@src/ts/props';
import { COMPANY_NAME } from '@src/config';

export const Meta: React.FC<MetaProps> = ({ title, keywords, description }) => {
    return (
        <Head>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />
            <meta charSet="utf-8" />
            <link rel="icon" href="/favicon.ico" />
            <title>
                {COMPANY_NAME} | {title}
            </title>
            <link
                href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
                rel="stylesheet"
            />
        </Head>
    );
};

Meta.defaultProps = {
    title: 'Staking',
    keywords:
        'blockchain, cryptocurrency, crypto, incubator, smart vesting, tokenized assets, smart contracts, community rewards, social incubation, easy staking, fair allocation, cross pooling, decubate',
    description: 'Staking made easy',
};

export default Meta;
