import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { PageLoader } from './Loader';

const ConnectionErrorModal = dynamic(
    import('@src/components/Modal/ConnectionErrorModal'),
);
const IncorrectNetworkModal = dynamic(
    import('@src/components/Modal/IncorrectNetworkModal'),
);
const StakingDrawer = dynamic(import('@src/components/Staking/StakingDrawer'));

import Meta from './Meta';
import Container from './Container';
import Footer from './Footer';
import Header from './Header';

export const Layout: React.FC = ({ children }) => {
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        router.events.on('routeChangeStart', () => {
            setLoading(true);
        });
        router.events.on('routeChangeComplete', () => {
            setLoading(false);
        });
        return () => {
            router.events.off('routeChangeStart', () => {
                return;
            });
            router.events.off('routeChangeComplete', () => {
                return;
            });
        };
    }, [router.events]);

    return (
        <div
            data-testid="layout"
            className="w-full fixed h-screen bottom-0 left-0 overflow-auto"
        >
            <Meta />
            <Header />
            <main className="content relative">
                <Container>
                    {loading ? <PageLoader text="" /> : children}
                </Container>
            </main>
            <Footer />
            <ConnectionErrorModal />
            <IncorrectNetworkModal />
            <StakingDrawer />
            <ToastContainer theme="dark" position="bottom-right" />
        </div>
    );
};

export { Content } from './Content';
export { Loader, PageLoader } from './Loader';
export { Meta } from './Meta';
