import Link from 'next/link';

import {
    COMPANY_NAME,
    IS_DARK_MODE,
    PRIVACY,
    TERMS,
    DISABLE_DECUBATE_FOOTER,
} from '@src/config';

const links: { name: string; href: string; target?: string }[] = [
    {
        name: 'Privacy policy',
        href: PRIVACY,
        target: '__blank',
    },
    {
        name: 'Terms & Conditions',
        href: TERMS,
        target: '__blank',
    },
];

const haechi_logo = {
    true: 'Light',
    false: 'Dark',
};

const Footer: React.FC = () => {
    return (
        <div className="max-w-screen-xl mx-auto ">
            {!DISABLE_DECUBATE_FOOTER && (
                <div className="ml-4 md:ml-0 text-left my-10 md:flex justify-center items-center md:space-x-20">
                    <div className="">
                        <p className="mt-2 mb-1 text-secondary">Audited by</p>
                        <img
                            src={`/img/HaechiLogo-${
                                haechi_logo[IS_DARK_MODE?.toString()]
                            }.png`}
                            style={{ width: '150px', height: '22px' }}
                        />
                    </div>
                    <Link href="https://decubate.com">
                        <a
                            target="_blank"
                            className="my-8 sm:my-0"
                            ref="noopener,noreferrer"
                        >
                            <p className="mt-2 text-secondary">Powered by</p>
                            <div className="flex space-x-2 items-center">
                                <img
                                    src={`/img/Logo-Mobile.png`}
                                    style={{ width: '25px', height: '25px' }}
                                />
                                <h3 className="text-2xl">
                                    <strong>Decubate</strong>
                                </h3>
                            </div>
                        </a>
                    </Link>
                </div>
            )}
            <div className="border-t-2 border-primary text-primary px-4 md:flex md:flex-row-reverse items-center">
                <div className="flex items-center space-x-12 text-secondary mt-4 md:mt-0">
                    {links
                        .filter(({ href }) => !!href)
                        .map(({ name, href, target }) => (
                            <Link href={href} key={name}>
                                <a
                                    className="text-sm hover:text-primary my-2"
                                    target={target}
                                    rel="noopener"
                                >
                                    {name}
                                </a>
                            </Link>
                        ))}
                </div>
                <p className="my-4 flex-1">
                    © COPYRIGHT {new Date().getFullYear()} {COMPANY_NAME} | All
                    rights reserved
                </p>
            </div>
        </div>
    );
};

export default Footer;
